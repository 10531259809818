<template>
<div>
  <b-container fluid class="bg-white mt-4">
    <b-row class="mb-4">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Obligations</h1>
        <p>Explore regulatory obligations related to commodities sector.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="mb-4" style="display: flex; height: 30px;">
          <filter-interface class="mr-4" namespace="commodities" :rows="rows" v-model="rowsFiltered" :staticDataProperties="staticDataProperties" :includeTaxonomy="false"></filter-interface>
          <sort-interface class="mr-4" namespace="commodities" :rows="rowsFiltered" v-model="rowsSorted" :optionsProperty="sortProperties"></sort-interface>
          <search-interface namespace="commodities" :rows="rowsSorted" v-model="rowsSearched"></search-interface>
        </div>
        <div>
          <b-button variant="outline-primary" @click="download" class="mr-2 button">download in Excel</b-button>
        </div>
        <b-pagination
          inline
          v-model="currentPage"
          :total-rows="rowsSearched.length"
          :per-page="perPage"
          class="mt-3"
        ></b-pagination>
          <div class="mb-3" v-for="item in showRowsPage" :key="item.id">
            <row :item="item"></row>
          </div>
        <b-pagination
          inline
          v-model="currentPage"
          :total-rows="rowsSearched.length"
          :per-page="perPage"
          class="mt-3"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import workflow from '@/workflows/annotation'
import FilterInterface from '@/components/FilterInterfaceV4.vue'
import Row from '@/components/ObligationCommodities.vue'
import SearchInterface from '@/components/SearchInterfaceV1.vue'
import SortInterface from '@/components/SortInterfaceV1.vue'
import zipcelx from 'zipcelx'

export default {
  name: 'CommoditiesObligations',
  components: {
    FilterInterface,
    Row,
    SearchInterface,
    SortInterface
  },
  computed: {
    rows: function () {
      return this.$store.state.commoditiesObligations
    },
    showRowsPage: function () {
      const start = (this.currentPage - 1) * this.perPage
      const end = this.currentPage * this.perPage
      return this.rowsSearched.slice(start, end)
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      perPage: 20,
      currentPage: 1,
      rowsFiltered: [],
      rowsSearched: [],
      rowsSorted: [],
      sortProperties: [
        { value: 'created_at', text: 'Date created' },
        { value: 'updated_at', text: 'Date updated' },
        { value: 'name', text: 'Name' }
      ],
      staticDataProperties: [
        { name: 'Content type', property: 'obligationtype', graph: 'obligationtype', tree: true },
        { name: 'Entities', property: 'entities', graph: 'entities' },
        { name: 'Areas', property: 'areas', graph: 'areas' },
        { name: 'Initiatives', property: 'initiatives', graph: 'initiatives' },
        { name: 'Requirements', property: 'requirements', graph: 'requirements' },
        { name: 'Workflow', property: 'workflow', direct: true }
      ]
    }
  },
  created: function () {
    document.title = "KPMG Commodities Regulatory Initiatives"
    console.log(this.rows)
  },
  methods: {
    download: function () {
      this.$logger.debug('loading started')
      const data = []
      const fields = [
        { field: 'regulation_id', name: 'regulation id' },
        { field: 'regulation_name_short', name: 'regulation name short' },
        { field: 'regulation_country_code', name: 'regulation country code' },
        { field: 'regulation_topics', name: 'regulation topics' },
        { field: 'regulation_tags', name: 'regulation tags' },
        { field: 'regulation_date_in_force', name: 'regulation date in force' },
        { field: 'regulation_date_last_amended', name: 'regulation date last amended' },
        { field: 'regulator', name: 'regulator' },
        { field: 'initiative_id', name: 'initiative id' },
        { field: 'initiative_name', name: 'initiative name' },
        { field: 'initiative_country', name: 'initiative country' },
        { field: 'id', name: 'obligation id' },
        { field: 'scrollY', name: 'ordering in text' },
        { field: 'obligationtype', name: 'type' },
        { field: 'citation', name: 'citation' },
        { field: 'name', name: 'name' },
        { field: 'date_effective', name: 'effective date' },
        { field: 'description', name: 'description' },
        { field: 'context', name: 'context' },
        { field: 'standard', name: 'minimum standard' },
        { field: 'lagging', name: 'lagging market practice' },
        { field: 'market_practice', name: 'leading market practice' },
        { field: 'topic', name: 'topic' },
        { field: 'entities', name: 'entities' },
        { field: 'areas', name: 'functions' },
        { field: 'requirements', name: 'requirements' },
        { field: 'status_id', name: 'status id' },
        { field: 'status_name', name: 'status name' },
        { field: 'status_created_at', name: 'status created at' },
        { field: 'status_created_by', name: 'status created by' },
        { field: 'created_at', name: 'created at' },
        { field: 'created_by', name: 'created by' },
        { field: 'updated_at', name: 'updated at' },
        { field: 'updated_by', name: 'updated by' }
      ]
      const headings = _.map(fields, field => {
        return { value: field.name, type: 'string' }
      })
      data.push(headings)
      const dlSource = this.rowsSearched
      _.each(dlSource, annotation => {
        const row = _.map(fields, field => {
          if (field.field === 'date_effective') {
            let dateEffective = ''
            if (annotation[field.field]) {
              dateEffective = moment(annotation[field.field]).format('YYYY-MM-DD')
            }
            return { value: dateEffective, type: 'string' }
          }
          if (field.field === 'regulation_name_short' && annotation.regulation) {
            return { value: annotation.regulation.name_short, type: 'string' }
          }
          if (field.field === 'regulation_country_code' && annotation.regulation) {
            return { value: annotation.regulation.country_code, type: 'string' }
          }
          if (field.field === 'regulation_topics' && annotation.regulation) {
            return { value: annotation.regulation.regtopics.map(x => x.name), type: 'string' }
          }
          if (field.field === 'regulation_tags' && annotation.regulation) {
            return { value: annotation.regulation.tags.map(x => x.name), type: 'string' }
          }
          if (field.field === 'regulation_date_in_force' && annotation.regulation) {
            return { value: moment(annotation.regulation.date_in_force).format('YYYY-MM-DD'), type: 'string' }
          }
          if (field.field === 'regulation_date_last_amended' && annotation.regulation) {
            return { value: moment(annotation.regulation.date_last_amended).format('YYYY-MM-DD'), type: 'string' }
          }
          if (field.field === 'regulator' && annotation.regulation) {
            return { value: annotation.regulation.regulator.name, type: 'string' }
          }
          if (field.field === 'initiative_id' && annotation.initiatives[0]) {
            return { value: annotation.initiatives[0].id, type: 'string' }
          }
          if (field.field === 'initiative_name' && annotation.initiatives[0]) {
            return { value: annotation.initiatives[0].name, type: 'string' }
          }
          if (field.field === 'initiative_country' && annotation.initiatives[0]) {
            return { value: annotation.initiatives[0].country, type: 'string' }
          }
          if (field.field === 'text') {
            return { value: annotation[field.field].replace(/\s\s+/g, ' '), type: 'string' }
          }
          if (field.field === 'status_name') {
            return { value: workflow.find(x => x.id === annotation.status_id).name, type: 'string' }
          }
          if (field.field === 'obligationtype') {
            if (annotation[field.field]) {
              return { value: annotation[field.field].name, type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          if (field.field === 'entities') {
            if (annotation[field.field].length > 0) {
              return { value: annotation[field.field].map(x => x.name), type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          if (field.field === 'areas') {
            if (annotation[field.field].length > 0) {
              return { value: annotation[field.field].map(x => x.name), type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          if (field.field === 'requirements') {
            if (annotation[field.field].length > 0) {
              return { value: annotation[field.field].map(x => x.name), type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          return { value: annotation[field.field], type: 'string' }
        })
        data.push(row)
      })
      const config = { filename: 'annotations', sheet: { data: data } }
      zipcelx(config)
    }
  }
}
</script>

<style>
</style>
